var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-12"},[_c('h3',[_vm._v(_vm._s(_vm.title)+" ")]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center pt-2 pb-5"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.files,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading-text":"Loading... Please wait","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.docTypeName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.docTypeName))]),(item.docKindName)?_c('span',[_vm._v(_vm._s(" (" + item.docKindName + ")"))]):_vm._e()]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"dark":"","small":"","color":"primary"}},[_vm._v("Открыть ")])],1),(_vm.isRemovable)?_c('v-btn',{attrs:{"dark":"","small":"","color":"red"},on:{"click":function () {
          _vm.prompt = true
          _vm.removableFileId = item.id
        }}},[_vm._v("Удалить ")]):_vm._e()]}}],null,true)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.prompt),callback:function ($$v) {_vm.prompt=$$v},expression:"prompt"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',{staticClass:"text-h5 text-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.prompt=false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.removeFile}},[_vm._v("ДА")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }